import i18n from 'services/i18n'

export const SEARCH_PAGE_MKTPLACE_CHANGE_MODAL_NAME =
  'SearchPageMktplaceChangeModal'
export const SB_SAVE_ERRORS_MODAL_NAME = 'SBSaveErrosModal'
export const SB_NAME_SEGMENT_MODAL_NAME = 'SBNameSegmentModal'
export const TOP_BRANDS_COUNT = 4
export const TOP_CATEGORIES_COUNT = 4
export const FILTERS_MODAL_MAX_ITEMS = 30
export const NAME_MAX_LENGTH = 180
export const ADDITIONAL_ASIN_LIMIT = 1000 // technical limitation
export const KEYWORDS_TITLE_LIMIT = 500
export const BRANDS_LIMIT = 500
export const EXCLUDE_KEYWORDS_LIMIT = 1000
export const EXCLUDE_ASINS_LIMIT = 1000
export const MAX_ASIN_COUNT_THRESHOLD = 100000

export const TIPS = {
  keywords: i18n.t(
    'market_insights:SegmentBuilder.keywordsTip',
    'This broad match will pull in all ASINs that have the selected Title Keywords anywhere in the product title. Be sure to include both singular and plural keywords and special characters, such as apostrophes or dashes.'
  ),
  brands: i18n.t(
    'market_insights:SegmentBuilder.brandsTip',
    "Enter the brand name exactly as it appears on the Amazon listing page (don't worry about capitalization)."
  ),
  categories: i18n.t(
    'market_insights:SegmentBuilder.categoriesTip',
    'When you select a category, all of its subcategories will be included in the segment. You can also select more than one category or subcategory.'
  ),
  asins: i18n.t(
    'market_insights:SegmentBuilder.asinsTip',
    'ASINs can be line or comma separated. You can include up to 10k ASINs.'
  )
}

// TODO: To be removed after we add getting real data from API
export const elements = [
  {
    id: 1,
    isExpanded: false,
    isSelected: 'unchecked', // checked, unchecked, partial
    hasChildren: true,
    path: [0],
    data: { label: 'First item', productCount: 321 }
  },
  {
    id: 2,
    isSelected: 'unchecked',
    isExpanded: false,
    hasChildren: true,
    path: [1],
    data: { label: 'Second item', productCount: 521 }
  },
  {
    id: 3,
    isSelected: 'unchecked',
    isExpanded: false,
    hasChildren: true,
    path: [2],
    data: { label: 'Third item', productCount: 34 }
  }
]
