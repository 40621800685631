import React from 'react'
import { Translation } from 'react-i18next'

export const SEGMENT_DETAILS_TABLE_NAMES = {
  segmentName: (
    <Translation ns="market_insights">
      {t =>
        t('market_insights:SegmentDetailsTable.segmentName', 'Segment Name')
      }
    </Translation>
  ),
  marketplace: (
    <Translation ns="market_insights">
      {t => t('market_insights:SegmentDetailsTable.marketplace', 'Marketplace')}
    </Translation>
  ),
  category: (
    <Translation ns="market_insights">
      {t => t('market_insights:SegmentDetailsTable.category', 'Category')}
    </Translation>
  ),
  categoriesIncluded: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:SegmentDetailsTable.categoryIncluded',
          'Categories Included'
        )
      }
    </Translation>
  ),
  categoriesExcluded: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:SegmentDetailsTable.categoryExcluded',
          'Categories Excluded'
        )
      }
    </Translation>
  ),
  searchTerms: (
    <Translation ns="market_insights">
      {t =>
        t('market_insights:SegmentDetailsTable.searchTerms', 'Search Terms')
      }
    </Translation>
  ),
  price: (
    <Translation ns="market_insights">
      {t => t('market_insights:SegmentDetailsTable.price', 'Price')}
    </Translation>
  ),
  reviews: (
    <Translation ns="market_insights">
      {t => t('market_insights:SegmentDetailsTable.reviews', 'Reviews')}
    </Translation>
  ),
  rating: (
    <Translation ns="market_insights">
      {t => t('market_insights:SegmentDetailsTable.rating', 'Rating')}
    </Translation>
  ),
  revenue: (
    <Translation ns="market_insights">
      {t => t('market_insights:SegmentDetailsTable.revenue', 'Revenue')}
    </Translation>
  ),
  keywordsIncluded: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:SegmentDetailsTable.productTitlesIncluded',
          'Product Titles Included'
        )
      }
    </Translation>
  ),
  keywordsExcluded: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:SegmentDetailsTable.productTitlesExcluded',
          'Product Titles Excluded'
        )
      }
    </Translation>
  ),
  asinsIncluded: (
    <Translation ns="market_insights">
      {t =>
        t('market_insights:SegmentDetailsTable.asinsIncluded', 'ASINs Included')
      }
    </Translation>
  ),
  asinsExcluded: (
    <Translation ns="market_insights">
      {t =>
        t('market_insights:SegmentDetailsTable.asinsExcluded', 'ASINs Excluded')
      }
    </Translation>
  ),
  brandsIncluded: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:SegmentDetailsTable.brandsIncluded',
          'Brands Included'
        )
      }
    </Translation>
  ),
  brandsExcluded: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:SegmentDetailsTable.brandsExcluded',
          'Brands Excluded'
        )
      }
    </Translation>
  ),
  static: (
    <Translation ns="market_insights">
      {t =>
        t('market_insights:SegmentManagerTable.segmentState', 'Segment State')
      }
    </Translation>
  )
}

export const CHART_LABEL_NAMES = t => ({
  brands: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.brands',
    'Brands'
  ),
  asins: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.asins',
    'ASINs'
  ),
  avgReviews: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.avgReviews',
    'Avg Reviews'
  ),
  avgPrice: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.medianPrice',
    'Median Price'
  ),
  unitSales: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.unitSales',
    'Unit Sales'
  ),
  revenue: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.revenue',
    'Revenue'
  ),
  revenue1p: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.revenue1p',
    '1P Revenue'
  ),
  revenue3p: t(
    'market_insights:ShareAndTrends.lineChartGraph.labels.revenue3p',
    '3P Revenue'
  )
})

export const PRODUCT_TRENDS_LABEL_NAMES = t => ({
  brand: t(
    'market_insights:ShareAndTrends.ProductTrends.labels.brand',
    'Brand'
  ),
  asin: t('market_insights:ShareAndTrends.ProductTrends.labels.asin', 'ASIN'),
  rank: t('market_insights:ShareAndTrends.ProductTrends.labels.rank', 'Rank'),
  name: t(
    'market_insights:ShareAndTrends.ProductTrends.labels.productName',
    'Product Name'
  ),
  revenue: t(
    'market_insights:ShareAndTrends.ProductTrends.labels.revenue',
    'Revenue'
  ),
  shareTrend: t(
    'market_insights:ShareAndTrends.ProductTrends.labels.shareTrend',
    'Trend (Percentage Change)'
  )
})
