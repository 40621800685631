export const LISTING_DESCRIPTION_REGEX = ` style="[a-zA-Z0-9:;\\.\\s\\(\\)\\-\\,]*"| fr-original-style="[a-zA-Z0-9:;\\.\\s\\(\\)\\-\\,]*"`

/*
  u3040 - u30ff : Hiragana, Katakana
  u31f0 - u31ff : Katakana phonetic extensions
  u3400 - u4dbf : CJK Unified Ideographs Extension A
  u4e00 - u9fff : CJK Unified Ideographs
  uf900 - ufaff : CJK Compatibility Ideographs
*/
export const asianIdeographRegex = new RegExp(
  `[\u3040-\u30ff\u31f0-\u31ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff]`
)
