import { Trans, useTranslation } from 'react-i18next'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from '@junglescout/edna'

const StyledSpan = styled.span`
  line-height: 20px;
`

const elementOrString = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.string
])

export function RevenueHeaderTooltip({ children }) {
  const { t } = useTranslation('market_insights')

  return (
    <Tooltip
      size="medium"
      side="left"
      content={
        <Trans
          i18nKey="market_insights:ShareAndTrends.sellersTable.tooltips.header.revenue"
          t={t}>
          <StyledSpan>
            <b>Seller Row:</b> seller&apos;s total revenue
            <br />
            <b>Product Row:</b> product revenue
          </StyledSpan>
        </Trans>
      }>
      <span>{children}</span>
    </Tooltip>
  )
}

RevenueHeaderTooltip.propTypes = {
  children: elementOrString.isRequired
}

export function RevenueSubheaderTooltip({ children }) {
  const { t } = useTranslation('market_insights')

  return (
    <Tooltip
      size="medium"
      side="right"
      content={
        <Trans
          i18nKey="market_insights:ShareAndTrends.sellersTable.tooltips.subHeader.revenue"
          t={t}>
          <StyledSpan>
            <b>Seller Row:</b> Product revenue divided by total product revenue
            for this seller
            <br />
            <b>Product Row:</b> Product revenue divided by total product revenue
            for this product
          </StyledSpan>
        </Trans>
      }>
      <span>{children}</span>
    </Tooltip>
  )
}

RevenueSubheaderTooltip.propTypes = {
  children: elementOrString.isRequired
}

export function UnitSalesHeaderTooltip({ children }) {
  const { t } = useTranslation('market_insights')

  return (
    <Tooltip
      size="medium"
      side="left"
      content={
        <Trans
          i18nKey="market_insights:ShareAndTrends.sellersTable.tooltips.header.unitSales"
          t={t}>
          <StyledSpan>
            <b>Seller Row:</b> seller’s total unit sales
            <br />
            <b>Product row:</b> products unit sales
          </StyledSpan>
        </Trans>
      }>
      <span>{children}</span>
    </Tooltip>
  )
}

UnitSalesHeaderTooltip.propTypes = {
  children: elementOrString.isRequired
}

export function UnitSalesSubheaderTooltip({ children }) {
  const { t } = useTranslation('market_insights')

  return (
    <Tooltip
      size="medium"
      side="right"
      content={
        <Trans
          i18nKey="market_insights:ShareAndTrends.sellersTable.tooltips.subHeader.unitSales"
          t={t}>
          <StyledSpan>
            <b>Seller Row:</b> Number of unit sales divided by total unit sales
            for this seller
            <br />
            <b>Product Row:</b> Number of unit sales divided by total unit sales
            for this product
          </StyledSpan>
        </Trans>
      }>
      <span>{children}</span>
    </Tooltip>
  )
}

UnitSalesSubheaderTooltip.propTypes = {
  children: elementOrString.isRequired
}

export function BuyboxWinPercentageRowTooltip({ depth, children }) {
  const { t } = useTranslation('market_insights')

  const text =
    depth === 0
      ? t(
          'market_insights:ShareAndTrends.sellersTable.tooltips.sellerBBWinPercentage',
          'Number of times this seller won the Buy Box relative to all other sellers'
        )
      : t(
          'market_insights:ShareAndTrends.sellersTable.tooltips.productBBWinPercentage',
          'Number of times this product won the Buy Box for this seller divided by the total number of times this product won the Buy Box'
        )

  return (
    <Tooltip size="medium" side="left" content={text}>
      <span>{children}</span>
    </Tooltip>
  )
}

BuyboxWinPercentageRowTooltip.propTypes = {
  depth: PropTypes.number.isRequired,
  children: elementOrString.isRequired
}

export function LinkTooltip({ children }) {
  const { t } = useTranslation('market_insights')

  return (
    <Tooltip
      size="190px"
      side="bottom"
      content={t(
        'market_insights:ShareAndTrends.ExcelExportsTable.tooltip.link',
        'Exports expire after 14 days'
      )}>
      <span>{children}</span>
    </Tooltip>
  )
}
