import { PRODUCT_TRENDS_LABEL_NAMES } from './display_names'

export const TREE_MAP_MAPPING = {
  revenue: {
    valueTrend: 'revenueTrend',
    size: 'revenue'
  }
}

export const PRODUCT_TRENDS_CSV_EXPORT_COLUMNS = t => {
  return {
    accessor: ['rank', 'name', 'brand', 'asin', 'revenue', 'shareTrend'],
    columnName: [
      PRODUCT_TRENDS_LABEL_NAMES(t).rank,
      PRODUCT_TRENDS_LABEL_NAMES(t).name,
      PRODUCT_TRENDS_LABEL_NAMES(t).brand,
      PRODUCT_TRENDS_LABEL_NAMES(t).asin,
      PRODUCT_TRENDS_LABEL_NAMES(t).revenue,
      PRODUCT_TRENDS_LABEL_NAMES(t).shareTrend
    ]
  }
}
