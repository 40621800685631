import { SETTINGS_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/settings'

export const DE_LANGUAGE_CODE = 'de-DE'
export const ES_LANGUAGE_CODE = 'es-ES'
export const FR_LANGUAGE_CODE = 'fr-FR'
export const IT_LANGUAGE_CODE = 'it-IT'

export const LANGUAGES = [
  {
    value: 'en-US',
    label: 'English',
    adminLabel: 'English',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.English,
    column: 'left',
    ISO639_2: 'eng'
  },
  {
    value: 'de-DE',
    label: 'Deutsch',
    adminLabel: 'German',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.German,
    column: 'left',
    ISO639_2: 'deu'
  },
  {
    value: 'es-ES',
    label: 'Español',
    adminLabel: 'Spanish',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.Spanish,
    column: 'left',
    ISO639_2: 'spa'
  },
  {
    value: 'fr-FR',
    label: 'Français',
    adminLabel: 'French',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.French,
    column: 'left',
    ISO639_2: 'fra'
  },
  {
    value: 'it-IT',
    label: 'Italiano',
    adminLabel: 'Italian',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.Italian,
    column: 'left',
    ISO639_2: 'ita'
  },
  {
    value: 'zh-Hant',
    label: '中文简体',
    adminLabel: 'Chinese',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.Chinese,
    column: 'right',
    ISO639_2: 'chi'
  },
  {
    value: 'ja-JP',
    label: '日本語',
    adminLabel: 'Japanese',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.Japanese,
    column: 'right',
    ISO639_2: 'jpn'
  },
  {
    value: 'hi-IN',
    label: 'हिंदी',
    adminLabel: 'Hindi',
    dataId: SETTINGS_DATA_ATTRIBUTE_IDS.Languages.Hindi,
    column: 'right',
    ISO639_2: 'hin'
  }
]
