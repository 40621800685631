import React from 'react'
import { Translation, Trans } from 'react-i18next'

export const BRANDS_TABLE_TOOLTIPS = {
  numReviews: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.numReviews',
          'The total number of reviews the product has at the end of the selected time frame.'
        )
      }
    </Translation>
  ),
  category: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.category',
          'All the categories that the product appeared in during the selected time frame.'
        )
      }
    </Translation>
  ),
  subCategory: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.subCategory',
          'All the subcategories that the product appeared in during the selected time frame.'
        )
      }
    </Translation>
  ),
  rating: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.rating',
          'The product’s star rating at the end of the selected time frame.'
        )
      }
    </Translation>
  ),
  price: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.price',
          'The high, low, and average price during the selected time frame.'
        )
      }
    </Translation>
  ),
  sellerType: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.sellerType',
          'All seller types that owned the buy box with the selected time frame.'
        )
      }
    </Translation>
  ),
  numSellers: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.numSellers',
          'Number of sellers on the last date of the selected time frame.'
        )
      }
    </Translation>
  ),
  buyBoxOwner: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.buyBoxOwner',
          'Last Buy Box Owner at the end of the selected time frame.'
        )
      }
    </Translation>
  ),
  dimensions: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.dimensions',
          'Last updated Product dimensions.'
        )
      }
    </Translation>
  ),
  weight: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.weight',
          'Last updated Product weight.'
        )
      }
    </Translation>
  ),
  dateFirstAvailable: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.dateFirstAvailable',
          'The date this product was first available on Amazon. Amazon doesn’t provide a date for some products. Jungle Scout estimates the Date First Available for these products based on the earliest question or review.'
        )
      }
    </Translation>
  ),
  bsr: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.bsr',
          'Average BSR within the selected time frame.'
        )
      }
    </Translation>
  ),
  expandRow: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.expandRow',
          'Expand row to view product level data'
        )
      }
    </Translation>
  ),
  asinsCount: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.tooltips.asinsCount',
          'Count of ASINs that were active as of the last day of segment data. Count of ASINs may vary based on your selected date range and does not include parent ASINs.'
        )
      }
    </Translation>
  )
}

export const CUSTOMIZE_COLOMNS_MODAL_TOOLTIPS = {
  avgPrice: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.avgPrice',
          'The high, low, and average Buy Box price during the selected date range.'
        )
      }
    </Translation>
  ),
  reviews: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.reviews',
          'The total reviews the product has at the end of the selected date range.'
        )
      }
    </Translation>
  ),
  starRating: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.rating',
          "The product's star rating at the end of the selected date range."
        )
      }
    </Translation>
  ),
  category: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.category',
          'All the categories that the product appeared in during the selected date range.'
        )
      }
    </Translation>
  ),
  subCategory: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.subCategories',
          'All the subcategories that the product appeared in during the selected date range.'
        )
      }
    </Translation>
  ),
  numSellers: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.sellers',
          'Number of sellers on the last date of selected date range.'
        )
      }
    </Translation>
  ),
  sellerType: (
    <Trans i18nKey="market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.sellerTypes">
      All seller types* that owned the buy box within the selected date range.
      <br />
      <br />
      *Seller types include Fulfilled by Amazon (FBA), Fulfilled by Merchant
      (FBM) and Amazon.
    </Trans>
  ),
  dimensions: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.dimensions',
          'Last updated product dimensions'
        )
      }
    </Translation>
  ),
  weight: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.weight',
          'Last updated product weight'
        )
      }
    </Translation>
  ),
  bsr: (
    <Trans i18nKey="market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.bsr">
      Average BSR* within the selected date range.
      <br />
      <br />
      *Best Seller Rank (BSR)
    </Trans>
  ),
  dateFirstAvailable: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.listedAt',
          "The date this product was first available on Amazon. Amazon doesn't provide a date for some products. Jungle Scout estimates the Date First Available for these products based on the earliest question or review."
        )
      }
    </Translation>
  ),
  revenue: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.revenue',
          'Revenue is calculated using estimated unit sales and the daily buy box price. It is updated daily and is a combination of all sales, both 1P and 3P.'
        )
      }
    </Translation>
  ),
  revenue1p: (
    <Trans i18nKey="market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.revenue1p">
      Estimated first-party seller (“1P”)* income from the sale of products.
      <br />
      <br />
      *1p vendors sell wholesale to Amazon.
    </Trans>
  ),
  revenue3p: (
    <Trans i18nKey="market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.revenue3p">
      Estimated third-party seller (3P)* income from the sale of products.
      <br />
      <br />
      *3p sells on Amazon and own the inventory.
    </Trans>
  ),
  unitSales: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.unitSales',
          'Estimated total unit sales for each product.'
        )
      }
    </Translation>
  ),
  unitSales1p: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.unitSales1p',
          "Estimated first-party sellers' total unit sales for each product."
        )
      }
    </Translation>
  ),
  unitSales3p: (
    <Translation ns="market_insights">
      {t =>
        t(
          'market_insights:ShareAndTrends.brandTrendsTable.costomizeColumnsModal.tooltip.unitSales3p',
          "Estimated third-party sellers' total unit sales for each product."
        )
      }
    </Translation>
  )
}
